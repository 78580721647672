import React from "react"
import Image from "@ui/image"
import {
  EspecialidadWrap,
  EspecialidadFoto,
  EspecialidadInfo,
  EspecialidadNombre,
} from "./style"

const Especialidad = especialidad => {
  const { nombre, foto_completa, descripcion } = especialidad.especialidad.data
  return (
    <EspecialidadWrap>
      <EspecialidadFoto>
        <Image src={foto_completa} alt={nombre.text} />
      </EspecialidadFoto>
      <EspecialidadInfo>
        <EspecialidadNombre>{nombre.text}</EspecialidadNombre>
        {nombre.text.length <= 25 ? <br /> : ""}
        <p>{descripcion.text}</p>
      </EspecialidadInfo>
    </EspecialidadWrap>
  )
}

export default Especialidad
