import * as React from "react"
import PropTypes from "prop-types"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/layout-03"
import Footer from "@layout/footer/layout-01"
import HeroArea from "@containers/especialidades/hero/layout-01"
import EspecialidadesArea from "@containers/especialidades/especialidades-area"
import ContactoArea from "@containers/index/contacto-area/layout-05"
import { headerData, footerData } from "@constants"

const EspecialidadesPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="Inicio" />
    <Header data={headerData} />
    <main className="site-wrapper-reveal">
      <HeroArea />
      <EspecialidadesArea />
      <ContactoArea />
    </main>
    <Footer data={footerData} />
  </Layout>
)

EspecialidadesPage.propTypes = {
  pageContext: PropTypes.shape({}),
  location: PropTypes.shape({}),
}

export default EspecialidadesPage
