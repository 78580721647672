import styled from "@styled"

export const EspecialidadWrap = styled.div`
  margin: 80px 0;
`

export const EspecialidadFoto = styled.div``

export const EspecialidadInfo = styled.div`
  background-color: #f0f0f0;
  line-height: 1.5em;
  max-width: 307px;
  text-align: center;
  padding: 30px 10px;
`

export const EspecialidadNombre = styled.h5`
  font-size: 28px;
  font-weight: 500;
`
