import React from "react"
import PropTypes from "prop-types"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { Container, Row, Col } from "@ui/wrapper"
import { useStaticQuery, graphql } from "gatsby"
import Text from "@ui/text"
import Button from "@ui/button"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Anchor from "@ui/anchor"
import { HeadingType, TextType, ButtonType, ImageType } from "@utils/types"
import {
  HeroWrapper,
  HeroTextBox,
  HeroSeparator,
  StyledSubtitle,
} from "./style"

const HeroArea = () => {
  const bannerData = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "banner-especialidades.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)
  const bgImage = convertToBgImage(getImage(bannerData.file.childImageSharp))
  return (
    <HeroWrapper {...bgImage}>
      <Container fluid>
        <Row justifyContent="right">
          <Col md={6}>
            <HeroTextBox>
              <StyledSubtitle as="h4">Nuestras especialidades</StyledSubtitle>
              <Text>
                Somos un consultorio dental que realiza todo tipo de
                tratamientos bucodentales de forma integral. Enfocados en que
                consigas la sonrisa que siempre has querido.
              </Text>
              <AnchorLink offset="200" href="#contacto">
                <Button key="boton1" mt="15px" shape="ellipse" hoverStyle="two">
                  Agenda una cita
                </Button>
              </AnchorLink>
              <Anchor offset="200" href="/#testimonios">
                <Button
                  color="white"
                  key="boton2"
                  mt="15px"
                  shape="ellipse"
                  ml="100px"
                  hoverStyle="three"
                >
                  Testimonios
                </Button>
              </Anchor>
            </HeroTextBox>
          </Col>
        </Row>
      </Container>
      <HeroSeparator>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1557.41 227.87">
          <path d="M1556.91.66V227.1l-1556,.27S.36,124.17.53,124.26C400.41,334.1,1556.91.66,1556.91.66Z" />
        </svg>
      </HeroSeparator>
    </HeroWrapper>
  )
}

HeroArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
  }),
}

export default HeroArea
