import styled, { themeGet, device } from "@styled"
import BackgroundImage from "gatsby-background-image"

export const HeroWrapper = styled(BackgroundImage)`
  //background-color: ${themeGet("colors.background")};
  position: relative;
  padding-block-start: 60px;
  padding-block-end: 120px;
  ${device.medium} {
    padding-block-start: 100px;
    padding-block-end: 200px;
  }
  ${device.large} {
    padding-block-start: 192px;
    padding-block-end: 384px;
  }
`

export const HeroTextBox = styled.div`
  color: #fff;
  max-width: 605px;
  width: 100%;
  padding-inline-start: 0;
  margin-block-start: 50px;
  margin-block-end: 20px;
  margin-inline: auto;
  text-align: center;

  ${device.medium} {
    padding-inline-start: 30px;
    margin-inline-start: auto;
    margin-inline-end: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    text-align: left;
  }
`

export const HeroSeparator = styled.div`
  position: absolute;
  bottom: -1px;
  display: block;
  width: 100%;
  z-index: 1;
  line-height: 0;
  svg {
    fill: #fff;
    height: 100%;
    width: 100%;
  }
  i {
    color: #f8f8f8;
    height: 100px;
    width: 100%;
  }
`

export const StyledSubtitle = styled.h4`
  font-weight: 700;
  color: ${themeGet("colors.primary")};
  font-size: 38px;
`
