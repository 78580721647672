import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "@ui/wrapper"
import Heading from "@ui/heading"
import { Slide } from "react-awesome-reveal"
import Especialidad from "@components/especialidad/layout-03"
import { EspecialidadWrapper } from "./especialidades-area.style"

const EspecialidadesArea = () => {
  const especialidadesData = useStaticQuery(graphql`
    {
      allPrismicEspecialidad(
        sort: { fields: data___numero_de_orden, order: ASC }
      ) {
        edges {
          node {
            data {
              nombre {
                text
              }
              foto_completa {
                gatsbyImageData
              }
              descripcion {
                text
              }
            }
          }
        }
      }
    }
  `)
  const especialidades = especialidadesData.allPrismicEspecialidad.edges
  return (
    <EspecialidadWrapper>
      <Container>
        <Row>
          <Col align="center">
            <Heading as="h3">
              Especialidades para el cuidado de tu familia
            </Heading>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              {especialidades.map(({ node: especialidad }, i) => (
                <Col lg={4} key={especialidad.data.nombre.text}>
                  <Slide right duration={100 * Math.pow(i, 2)}>
                    <Especialidad especialidad={especialidad} />
                  </Slide>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </EspecialidadWrapper>
  )
}

export default EspecialidadesArea
